import React, { lazy } from "react";
import "./App.css";
import { GlobalStateProvider } from "./components/common/GlobalState";

const HeaderLayout = lazy(() => import("./components/layouts/HeaderLayout"));
const RoutesPath = lazy(() => import("./routes/RoutesPath"));
const FooterLayout = lazy(() => import("./components/layouts/FooterLayout"));

function App() {
  return (
    <>
      <HeaderLayout />
      <div className="app-container">
        <div className="left-background"></div>
        <div className="right-background"></div>
        <div className="content">
          <GlobalStateProvider>
            <RoutesPath />
          </GlobalStateProvider>
        </div>
      </div>
      <FooterLayout />
    </>
  );
}

export default App;
