import React, { createContext, useContext, useState } from "react";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [apiData, setApiData] = useState();
  const [location, setLocation] = useState({ lat: null, lon: null }); 
  const [selectedState, setSelectedState] = useState(null);

  return (
    <GlobalStateContext.Provider value={{ apiData, setApiData, location, setLocation ,selectedState, setSelectedState}}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
