import React from "react";
import "../../../src/index.css";
import { Flex, Spin } from "antd";

const contentStyle = {
  padding: 50,
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

function PageLoading() {
  return (
    <div
      style={{
        background: "linear-gradient(90deg, #8DC63F 10%, #007DC1 100%)",
      
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex gap="middle" vertical>
        <Flex gap="middle">
          <Spin tip="Loading...Please Wait" size="large" style={{color:"#ffffff",}}>
            {content}
          </Spin>
        </Flex>
      </Flex>
    </div>
  );
}

export default PageLoading;
